<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData;
        "
      >
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :rules="searchRule"
          label-width="70px"
          :model="formInline"
          class="demo-form-inline"
          style="text-align: left"
        >
          <div class="search_box_title">
            {{ $t("searchModule.Query_Table") }}
          </div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.ReportPerson')">
                <el-input
                  v-model="formInline.operatorName"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <el-input
                  v-model="formInline.parkName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="时间" class="time_item">
                <el-date-picker
                  :picker-options="pickerOptions"
                  v-model="formInline.timeArray"
                  type="daterange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <!-- <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >查询
              </AuthorityComponent> -->
              <el-button
                type="primary"
                v-if="$route.meta.authority.button.query"
                icon="el-icon-search"
                @click="
                  searchData();
                  pageNum = 1;
                "
                :loading="loading"
                >查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                icon="el-icon-setting"
                v-if="$route.meta.authority.button.configure"
                @click="addSeting"
                type="info"
                >设置
              </el-button>
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <AuthorityComponent
            ComponentName="el-table-column"
            :permission="['button.view']"
            align="center"
            label="操作"
            width="80"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                style="padding: 0"
                @click="toDetail(scope.row)"
                >查看</el-button
              >
            </template>
          </AuthorityComponent>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat, setIndex } from "../../common/js/public.js";
// import picDetail from './picDetail'
export default {
  name: "carManager",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 20) {
        callback(new Error("最多输十一个字符"));
      }
    };
    const start = new Date();
    const end = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    return {
      // 禁用选择未来日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      modelvalue: "",
      dialogVisible: false,
      parkDetail: [],
      rowData: [],
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      searchRule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
        },
        {
          prop: "exceptDictName",
          label: this.$t("list.Exception"),
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
        },
        {
          prop: "operatorName",
          label: this.$t("list.ReportPerson"),
        },
        {
          prop: "reportTime",
          label: this.$t("list.Report_time"),
          width: "170",
        },
      ],
      tableData: [],
      formInline: {
        operatorName: "",
        timeArray: [
          dateFormat(start, "yyyy-MM-dd HH:mm:ss"),
          dateFormat(end, "yyyy-MM-dd HH:mm:ss"),
        ],
        parkName: "",
        startTime: "",
      },
    };
  },
  methods: {
    addSeting() {
      this.$router.push("/exceptionParkConfig");
      this.$setAuthority(
        "exceptionParkConfig",
        this.$route.meta.authority.subPage.configure
      );
    },
    parkHandleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    parkQuerySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1,2",
            dataSource: 1,
            areaIds: this.streetId ? this.streetId : this.areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            // this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 车牌号
    querySearchAsync(queryString, cb) {
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.loading = true;
      // if (this.formInline.plateNumber.length < 3) {
      //   this.formInline.plateNumber = '';
      //   this.formInline.carId = '';
      // }
      let data = { ...this.formInline };
      data.startTime =
        this.formInline.timeArray && this.formInline.timeArray.length
          ? this.formInline.timeArray[0]
          : "";
      data.endTime =
        this.formInline.timeArray && this.formInline.timeArray.length
          ? dateFormat(
              new Date(this.formInline.timeArray[1]),
              "yyyy-MM-dd 23:59:59"
            )
          : "";
      delete data.timeArray;
      // this.$axios.get('/acb/2.0/except/list', {
      //   data: {
      //     ...this.formInline,
      //     pageNum: this.pageNum,
      //     pageSize: this.pageSize
      //   }
      // }, {
      //   headers: {
      //     'Content-Type': 'application/json;charset=UTF-8'
      //   },
      // }).then(res => {
      //   console.log(11);
      // })
      const opt = {
        method: "post",
        url: "/acb/2.0/except/list",
        data: {
          ...data,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        success: (res) => {
          this.loading = false;
          this.tableData = setIndex(this.pageNum, res.value.list);
          this.total = res.value.total * 1 || 0;
        },
      };
      this.$request(opt);
    },
    // 进入详情页
    toDetail(row) {
      this.$router.push({
        path: "/abnormalAlarmDetail",
        query: {
          exceptReportId: row.exceptReportId,
        },
      });
    },
  },
  components: {
    // picDetail
  },
  activated() {
    this.searchData();
  },
  created() {
    console.log("权限=>", this.$route.meta.authority);
  },
  mounted() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
::v-deep .el-form-item.time_item {
  .el-date-editor.el-range-editor {
    min-width: 280px;

    .el-range-input {
      min-width: 90px;
    }

    .el-range-separator {
      min-width: 30px;
    }
  }
}

.el-icon-date-custom {
  position: relative;
  left: -26px;
}

::v-deep .el-form {
  padding: 0;
}

.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .chartsWrapper {
    height: 360px;

    // margin-bottom: 20px;
    .wrapper {
      background: #FFFFFF;
      border: 1px solid #C0CCDA;
      border-radius: 4px;

      .title {
        font-size: 14px;
        color: #475669;
        padding-left: 17px;
        height: 50px;
        line-height: 50px;
      }
    }
  }

  .searchWrapper {
    overflow: hidden;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  >>>.el-input-group__append {
    padding: 0;
  }
}
</style>
